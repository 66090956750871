export * from './auth.guards';
export * from './error.interceptor';
export * from './jwt.interceptor';
// export * from './fake-backend';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from '.';
import { ErrorInterceptor } from '.';

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
];