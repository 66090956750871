export const locale = {
  lang: 'fr',
  data: {
    LANGUAGE: {
      CHINESE: "Chinois",
      GERMAN: "Allemand",
      ENGLISH: "Anglais",
      SPANISH: "Espagnol",
      FRENCH: "Français",
      ITALIAN: "Italien",
      DUTCH: "Néerlandais",
      POLISH: "Polonais",
      PORTUGUESE: "Portugais",
      RUSSIAN: "Russe"
    },
    DROPDOWN: {
      ACCOUNT: "Mon compte",
      LOGOUT: "Déconnexion"
    },
    ROLES: {
      ADMIN: "Administrateur",
      USER: 'Utilisateur'
    }
  }
}