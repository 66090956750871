export const locale = {
  lang: 'en',
  data: {
    MENU: {
      DASHBOARD: "Dashboard",
      GAINS: "Gains",
      MISSIONS: {
        COLLAPSIBLE: "Missions",
        PROPOSED: "Proposed",
        CURRENT: "Current",
        CLOSED: "Closed"
      },
      AUDIT: "Collaborators Dashboard",
    }
  }
}
