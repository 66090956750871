export const locale = {
  lang: 'it',
  data: {
    LANGUAGE: {
      CHINESE: "Cinese",
      GERMAN: "Tedesco",
      ENGLISH: "Inglese",
      SPANISH: "Spagnolo",
      FRENCH: "Francese",
      ITALIAN: "Italiano",
      DUTCH: "Olandese",
      POLISH: "Polacco",
      PORTUGUESE: "Portoghese",
      RUSSIAN: "Russo"
    },
    DROPDOWN: {
      // ACCOUNT: "",
      // LOGOUT: ""
    }
  }
}