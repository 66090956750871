export const locale = {
  lang: 'de',
  data: {
    MENU: {
      DASHBOARD: "Armaturenbrett",
      GAINS: "Gewinne",
      MISSIONS: {
        COLLAPSIBLE: "Missionen",
        PROPOSED: "Vorgeschlagen",
        CURRENT: "Aktuell",
        CLOSED: "Abgeschlossen"
      }
    }
  }
}
