export const locale = {
  lang: "fr",
  data: {
    MENU: {
      DASHBOARD: { TITLE: "Tableau de bord" } ,
      CYBERSCORE: {
        TITLE: "Cyberscore",
        SUGGESTIONS: "Recommandations",
        ACTIONS_TRACKING: "Suivi des actions",
        TOOLS: 'Outils',
        REPOSITORY: 'Mon référentiel'
      },
      ACTIVES: {
        TITLE: "Actifs",
        DISPLAY_ACTIVES: "Afficher les actifs",
        ADD_ACTIVE: "Ajouter un actif",
      },
      CONTRIBUTORS: {
        TITLE: "Collaborateurs",
        CONTRIBUTORS_LIST: 'Liste des salariés'
      },
      ONBOARDING: {
        TITLE: "Onboarding",
      },
    },
  },
};
