export const locale = {
  lang: 'pl',
  data: {
    LANGUAGE: {
      CHINESE: "Chiński",
      GERMAN: "Niemiecki",
      ENGLISH: "Język angielski",
      SPANISH: "Hiszpański",
      FRENCH: "Francuski",
      ITALIAN: "Włoski",
      DUTCH: "Holenderski",
      POLISH: "Polskie",
      PORTUGUESE: "Portugalski",
      RUSSIAN: "Rosyjski"
    },
    DROPDOWN: {
      // ACCOUNT: "",
      // LOGOUT: ""
    }
  }
}