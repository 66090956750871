import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { User, Role } from 'app/auth/models';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  // public
  public currentUser: Observable<User>;

  // private
  private currentUserSubject: BehaviorSubject<User>;

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(private _http: HttpClient, private _toastrService: ToastrService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /**
   *  Confirms if user is admin
   */
  // get isAdmin() {
  //   return this.currentUser && this.currentUserSubject.value.role === Role.Admin;
  // }

  /**
   *  Confirms if user is client
   */
  // get isClient() {
  //   return this.currentUser && this.currentUserSubject.value.role === Role.Client;
  // }

  /**
   * Set current user in local storage
   *
   * @param user
   */
  private setLocalStorageCurrentUser(user: any, auth: any): User {
    const currentUser: User = {
      id: user.id,
      email: user.email,
      firstName: user.first_name,
      lastName: user.last_name,
      avatar: user.picture,
      token: auth.token,
      admin: user.isadmin,
      roles: user.roles,
      wave_target_uuid: user.wave_target_uuid,
      society_id: user.society_id
      // permissions: user.user_permission.backoffice_menu_permissions
    };

    localStorage.setItem('currentUser', JSON.stringify(currentUser));
    return currentUser;
  }

  /**
   * User login
   *
   * @param email
   * @param password
   * @returns user
   */
  public login(email: string, password: string): Observable<any> {
    return this._http
      .post<any>(`${environment.apiUrl}/login`, { email, password })
      .pipe(
        map(data => {
          const user: any = data.user;
          const auth: any = data.authorisation;

          // login successful if there's a jwt token in the response
          if (user && auth.token) {
            // store user details and token in local storage to keep user logged in between page refreshes
            const currentUser: User = this.setLocalStorageCurrentUser(user, auth);

            // notify
            this.currentUserSubject.next(currentUser);
          }

          return user;
        })
      );
  }

  /**
   * User register
   */
  public register(user_infos: any): Observable<any> {
    return this._http
      .post<any>(`${environment.apiUrl}/register`, user_infos)
      .pipe(
        map(data => {
          const user: any = data.user;
          const auth: any = data.authorisation;           

          // register successful if there's a token in the response
          if (user && auth && auth.token) {
            // store user details and token in local storage to keep user logged in between page refreshes
            this.setLocalStorageCurrentUser(user, auth);

            // notify
            this.currentUserSubject.next(user);
          }

          return user;
        })
      );
  }

  /**
   * Password recovery
   * @param email user email
   *
   */
  public passwordRecovery(email: string): Observable<any> {
    return this._http.post(`${environment.apiUrl}/password/recovery`, { email });
  }

  /**
   * User logout
   *
   */
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    // notify
    this.currentUserSubject.next(null);
  }
}
