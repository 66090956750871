import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from 'app/auth/service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  /**
   *
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   */
  constructor(private _router: Router, private _authenticationService: AuthenticationService) {}

  // canActivate
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const currentUser = this._authenticationService.currentUserValue;
    if (currentUser) {
      // check if route is restricted by permission
      // if (route.data.permission) {
      //   const permission: any = currentUser.permissions[route.data.permission];

      //   if (!currentUser.admin || (permission && !permission.consult)) {
      //     // not allowed so redirect to not-authorized page
      //     this._router.navigate(['/pages/miscellaneous/not-authorized']);
      //     return false;
      //   }
      // }

      // authorised so return true
      return true;
    }
    // not logged in so redirect to login page with the return url
    this._router.navigate(['/authentication/signin'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
