import { Injectable } from '@angular/core';

import { Country } from 'app/auth/models';

@Injectable({ providedIn: 'root' })
export class CountryService {
    // public
    public countries: Country [] = [
        { slug: 'AF', name: 'AFGHANISTAN' },
        { slug: 'AL', name: 'ALBANIA' },
        { slug: 'DZ', name: 'ALGERIA' },
        { slug: 'AD', name: 'ANDORRA' },
        { slug: 'AO', name: 'ANGOLA' },
        { slug: 'AI', name: 'ANGUILLA' },
        { slug: 'AG', name: 'ANTIGUA_AND_BARBUDA' },
        { slug: 'AR', name: 'ARGENTINA' },
        { slug: 'AM', name: 'ARMENIA' },
        { slug: 'AW', name: 'ARUBA' },
        { slug: 'AU', name: 'AUSTRALIA' },
        { slug: 'AT', name: 'AUSTRIA' },
        { slug: 'AZ', name: 'AZERBAIJAN_REPUBLIC' },
        { slug: 'BS', name: 'BAHAMAS' },
        { slug: 'BH', name: 'BAHRAIN' },
        { slug: 'BB', name: 'BARBADOS' },
        { slug: 'BY', name: 'BELARUS' },
        { slug: 'BE', name: 'BELGIUM' },
        { slug: 'BZ', name: 'BELIZE' },
        { slug: 'BJ', name: 'BENIN' },
        { slug: 'BM', name: 'BERMUDA' },
        { slug: 'BT', name: 'BHUTAN' },
        { slug: 'BO', name: 'BOLIVIA' },
        { slug: 'BA', name: 'BOSNIA_AND_HERZEGOVINA' },
        { slug: 'BW', name: 'BOTSWANA' },
        { slug: 'BR', name: 'BRAZIL' },
        { slug: 'BN', name: 'BRUNEI' },
        { slug: 'BG', name: 'BULGARIA' },
        { slug: 'BF', name: 'BURKINA_FASO' },
        { slug: 'BI', name: 'BURUNDI' },
        { slug: 'KH', name: 'CAMBODIA' },
        { slug: 'CM', name: 'CAMEROON' },
        { slug: 'CA', name: 'CANADA' },
        { slug: 'CV', name: 'CAPE_VERDE' },
        { slug: 'KY', name: 'CAYMAN_ISLANDS' },
        { slug: 'TD', name: 'CHAD' },
        { slug: 'CL', name: 'CHILE' },
        { slug: 'C2', name: 'CHINA_WORLDWIDE' },
        { slug: 'CO', name: 'COLOMBIA' },
        { slug: 'KM', name: 'COMOROS' },
        { slug: 'CK', name: 'COOK_ISLANDS' },
        { slug: 'CR', name: 'COSTA_RICA' },
        { slug: 'HR', name: 'CROATIA' },
        { slug: 'CU', name: 'CUBA' },
        { slug: 'CY', name: 'CYPRUS' },
        { slug: 'CZ', name: 'CZECH_REPUBLIC' },
        { slug: 'KP', name: 'DEMOCRATIC_PEOPLES_REPUBLIC_OF_KOREA' },
        { slug: 'CD', name: 'DEMOCRATIC_REPUBLIC_OF_THE_CONGO' },
        { slug: 'DK', name: 'DENMARK' },
        { slug: 'DJ', name: 'DJIBOUTI' },
        { slug: 'DM', name: 'DOMINICA' },
        { slug: 'DO', name: 'DOMINICAN_REPUBLIC' },
        { slug: 'EC', name: 'ECUADOR' },
        { slug: 'EG', name: 'EGYPT' },
        { slug: 'SV', name: 'EL_SALVADOR' },
        { slug: 'GQ', name: 'EQUATORIAL_GUINEA' },
        { slug: 'ER', name: 'ERITREA' },
        { slug: 'EE', name: 'ESTONIA' },
        { slug: 'SZ', name: 'ESWATINI' },
        { slug: 'ET', name: 'ETHIOPIA' },
        { slug: 'FK', name: 'FALKLAND_ISLANDS' },
        { slug: 'FO', name: 'FAROE_ISLANDS' },
        { slug: 'FJ', name: 'FIJI' },
        { slug: 'FI', name: 'FINLAND' },
        { slug: 'FR', name: 'FRANCE' },
        { slug: 'GF', name: 'FRENCH_GUIANA' },
        { slug: 'PF', name: 'FRENCH_POLYNESIA' },
        { slug: 'GA', name: 'GABON_REPUBLIC' },
        { slug: 'GM', name: 'GAMBIA' },
        { slug: 'GE', name: 'GEORGIA' },
        { slug: 'DE', name: 'GERMANY' },
        { slug: 'GH', name: 'GHANA' },
        { slug: 'GI', name: 'GIBRALTAR' },
        { slug: 'GR', name: 'GREECE' },
        { slug: 'GL', name: 'GREENLAND' },
        { slug: 'GD', name: 'GRENADA' },
        { slug: 'GP', name: 'GUADELOUPE' },
        { slug: 'GT', name: 'GUATEMALA' },
        { slug: 'GN', name: 'GUINEA' },
        { slug: 'GW', name: 'GUINEA_BISSAU' },
        { slug: 'GY', name: 'GUYANA' },
        { slug: 'HN', name: 'HONDURAS' },
        { slug: 'HK', name: 'HONG_KONG' },
        { slug: 'IS', name: 'ICELAND' },
        { slug: 'IN', name: 'INDIA' },
        { slug: 'ID', name: 'INDONESIA' },
        { slug: 'IR', name: 'IRAN' },
        { slug: 'IQ', name: 'IRAQ' },
        { slug: 'IE', name: 'IRELAND' },
        { slug: 'IL', name: 'ISRAEL' },
        { slug: 'IT', name: 'ITALY' },
        { slug: 'JM', name: 'JAMAICA' },
        { slug: 'JP', name: 'JAPAN' },
        { slug: 'JO', name: 'JORDAN' },
        { slug: 'KZ', name: 'KAZAKHSTAN' },
        { slug: 'KE', name: 'KENYA' },
        { slug: 'KI', name: 'KIRIBATI' },
        { slug: 'KW', name: 'KUWAIT' },
        { slug: 'KG', name: 'KYRGYZSTAN' },
        { slug: 'LA', name: 'LAOS' },
        { slug: 'LV', name: 'LATVIA' },
        { slug: 'LS', name: 'LESOTHO' },
        { slug: 'LR', name: 'LIBERIA' },
        { slug: 'LY', name: 'LIBYA' },
        { slug: 'LI', name: 'LIECHTENSTEIN' },
        { slug: 'LT', name: 'LITHUANIA' },
        { slug: 'LU', name: 'LUXEMBOURG' },
        { slug: 'MG', name: 'MADAGASCAR' },
        { slug: 'MW', name: 'MALAWI' },
        { slug: 'MY', name: 'MALAYSIA' },
        { slug: 'MV', name: 'MALDIVES' },
        { slug: 'ML', name: 'MALI' },
        { slug: 'MT', name: 'MALTA' },
        { slug: 'MH', name: 'MARSHALL_ISLANDS' },
        { slug: 'MQ', name: 'MARTINIQUE' },
        { slug: 'MR', name: 'MAURITANIA' },
        { slug: 'MU', name: 'MAURITIUS' },
        { slug: 'YT', name: 'MAYOTTE' },
        { slug: 'MX', name: 'MEXICO' },
        { slug: 'FM', name: 'MICRONESIA' },
        { slug: 'MN', name: 'MONGOLIA' },
        { slug: 'ME', name: 'MONTENEGRO' },
        { slug: 'MS', name: 'MONTSERRAT' },
        { slug: 'MA', name: 'MOROCCO' },
        { slug: 'MZ', name: 'MOZAMBIQUE' },
        { slug: 'MM', name: 'MYANMAR' },
        { slug: 'NA', name: 'NAMIBIA' },
        { slug: 'NR', name: 'NAURU' },
        { slug: 'NP', name: 'NEPAL' },
        { slug: 'NL', name: 'NETHERLANDS' },
        { slug: 'AN', name: 'NETHERLANDS_ANTILLES' },
        { slug: 'NC', name: 'NEW_CALEDONIA' },
        { slug: 'NZ', name: 'NEW_ZEALAND' },
        { slug: 'NI', name: 'NICARAGUA' },
        { slug: 'NE', name: 'NIGER' },
        { slug: 'NU', name: 'NIUE' },
        { slug: 'NF', name: 'NORFOLK_ISLAND' },
        { slug: 'NO', name: 'NORWAY' },
        { slug: 'OM', name: 'OMAN' },
        { slug: 'PK', name: 'PAKISTAN' },
        { slug: 'PW', name: 'PALAU' },
        { slug: 'PA', name: 'PANAMA' },
        { slug: 'PG', name: 'PAPUA_NEW_GUINEA' },
        { slug: 'PE', name: 'PERU' },
        { slug: 'PH', name: 'PHILIPPINES' },
        { slug: 'PN', name: 'PITCAIRN_ISLANDS' },
        { slug: 'PL', name: 'POLAND' },
        { slug: 'PT', name: 'PORTUGAL' },
        { slug: 'QA', name: 'QATAR' },
        { slug: 'CG', name: 'REPUBLIC_OF_THE_CONGO' },
        { slug: 'MD', name: 'REPUBLIC_OF_MOLDOVA' },
        { slug: 'RE', name: 'REUNION' },
        { slug: 'RO', name: 'ROMANIA' },
        { slug: 'RU', name: 'RUSSIA' },
        { slug: 'RW', name: 'RWANDA' },
        { slug: 'KN', name: 'SAINT_KITTS_AND_NEVIS_ANGUILLA' },
        { slug: 'PM', name: 'SAINT_PIERRE_AND_MIQUELON' },
        { slug: 'VC', name: 'SAINT_VINCENT_AND_GRENADINES' },
        { slug: 'WS', name: 'SAMOA' },
        { slug: 'SM', name: 'SAN_MARINO' },
        { slug: 'ST', name: 'SAO_TOME_AND_PRINCIPE' },
        { slug: 'SA', name: 'SAUDI_ARABIA' },
        { slug: 'SN', name: 'SENEGAL' },
        { slug: 'RS', name: 'SERBIA' },
        { slug: 'SC', name: 'SEYCHELLES' },
        { slug: 'SL', name: 'SIERRA_LEONE' },
        { slug: 'SG', name: 'SINGAPORE' },
        { slug: 'SK', name: 'SLOVAKIA' },
        { slug: 'SI', name: 'SLOVENIA' },
        { slug: 'SB', name: 'SOLOMON_ISLANDS' },
        { slug: 'SO', name: 'SOMALIA' },
        { slug: 'ZA', name: 'SOUTH_AFRICA' },
        { slug: 'KR', name: 'SOUTH_KOREA' },
        { slug: 'ES', name: 'SPAIN' },
        { slug: 'LK', name: 'SRI_LANKA' },
        { slug: 'SH', name: 'ST_HELENA' },
        { slug: 'LC', name: 'ST_LUCIA' },
        { slug: 'SR', name: 'SURINAME' },
        { slug: 'SE', name: 'SWEDEN' },
        { slug: 'CH', name: 'SWITZERLAND' },
        { slug: 'SY', name: 'SYRIAN_ARAB_REPUBLIC' },
        { slug: 'TW', name: 'TAIWAN' },
        { slug: 'TJ', name: 'TAJIKISTAN' },
        { slug: 'TZ', name: 'TANZANIA' },
        { slug: 'TH', name: 'THAILAND' },
        { slug: 'TG', name: 'TOGO' },
        { slug: 'TO', name: 'TONGA' },
        { slug: 'TT', name: 'TRINIDAD_AND_TOBAGO' },
        { slug: 'TN', name: 'TUNISIA' },
        { slug: 'TR', name: 'TURKEY' },
        { slug: 'TM', name: 'TURKMENISTAN' },
        { slug: 'TC', name: 'TURKS_AND_CAICOS_ISLANDS' },
        { slug: 'TV', name: 'TUVALU' },
        { slug: 'UG', name: 'UGANDA' },
        { slug: 'UA', name: 'UKRAINE' },
        { slug: 'AE', name: 'UNITED_ARAB_EMIRATES' },
        { slug: 'GB', name: 'UNITED_KINGDOM' },
        { slug: 'TZ', name: 'UNITED_REPUBLIC_OF_TANZANIA' },
        { slug: 'US', name: 'UNITED_STATES_OF_AMERICA' },
        { slug: 'UY', name: 'URUGUAY' },
        { slug: 'UZ', name: 'UZBEKISTAN' },
        { slug: 'VU', name: 'VANUATU' },
        { slug: 'VA', name: 'VATICAN_CITY_STATE' },
        { slug: 'VE', name: 'VENEZUELA' },
        { slug: 'VN', name: 'VIETNAM' },
        { slug: 'VG', name: 'VIRGIN_ISLANDS_BRITISH' },
        { slug: 'WF', name: 'WALLIS_AND_FUTUNA_ISLANDS' },
        { slug: 'YE', name: 'YEMEN' },
        { slug: 'ZM', name: 'ZAMBIA' },
        { slug: 'ZW', name: 'ZIMBABWE' }
    ]

  constructor() {}
}
