import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';
import { User } from 'app/auth/models';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UserService {
  /**
   *
   * @param {HttpClient} _http
   */
  constructor(private _http: HttpClient) {}

  /**
   * Get all users
   */
  getAll() {
    return this._http.get<User[]>(`${environment.apiUrl}/users`);
  }

  /**
   * Get user by id
   */
  getById(id: number) {
    return this._http.get<User>(`${environment.apiUrl}/users/${id}`);
  }

  /**
   * Get current user
   */
   getCurrentUser() {
    return this._http.get<any>(`${environment.apiUrl}/users/me`);
  }

  getUserGains() {
    return this._http.post<any>(`${environment.apiUrl}/payments/usergains`, {});
  }

  /**
   * POST image
   *
   * @param picture
   * @param params
   * @returns image url
   */
  public uploadImage(picture: FormData, params?: HttpParams): Observable<any> {
    return this._http.post(`${environment.apiUrl}/images`, picture, { params });
  }

  /**
   * POST profiles clean
   *
   * @param id
   * @returns
   */
  public deleteAccount(id: number): Observable<any> {
    return this._http.post<any>(`${environment.apiUrl}/profiles/${id}/clean`, { data: 'OK' });
  }

  /**
   * GET last connections
   * @returns last connections to the connected account
   */
  public getAccountLastConnections(): Observable<any> {
    return this._http.get<any>(`${environment.apiUrl}/users/login/me`);
  }

  /**
   * PUT user avatar
   *
   * @param id
   * @param picture
   * @param params
   * @returns
   */
  public editUserAvatar(id: number | 'me', picture: string, params?: HttpParams): Observable<any> {
    return this._http.put<any>(`${environment.apiUrl}/users/${id}`, picture, { params })
      // .pipe(mergeMap(
      //   res => {

      //   }
      // ));
  }

  /**
   * POST user password
   *
   * @param body
   * @param params
   * @returns
   */
  public editUserPassword(body: Object, params?: HttpParams) {
    return this._http.post<any>(`${environment.apiUrl}/password/reset`, body, { params })
    .pipe(
      mergeMap(data => {
        return data;
      })
    )
  }

  /**
   * POST user details
   *
   * @param body
   * @param params
   * @returns
   */
  public editUserDetails(body: Object, params?: HttpParams): Observable<any> {
    return this._http.post<any>(`${environment.apiUrl}/users/me`, body,  { params })
  }

  /**
   * POST user details
   *
   * @param body
   * @param params
   * @returns
   */
   public editSocietyDetails(body: Object, params?: HttpParams): Observable<any> {
    return this._http.post<any>(`${environment.apiUrl}/society/me`, body,  { params })
  }

  /**
   * GET user details
   *
   * @param id
   * @param params
   * @returns user details
   */
  public getUserDetails(id: number | 'me', params?: HttpParams): Observable<any> {
    return this._http.get<any>(`${environment.apiUrl}/users/${id}`, { params });
  }

  /**
   * GET user details
   *
   * @returns user details
   */
  public getCurrentSociety(): Observable<any> {
    return this._http.get<any>(`${environment.apiUrl}/society`);
  }

  /**
   * GET targets proposed
   *
   * @param id
   * @returns list of proposed targets
   */
  public targetProposed(id: number | 'me', params?: HttpParams): Observable<any> {
    return this._http.get<any>(`${environment.apiUrl}/users/${id}/targets/proposed`, { params });
  }

  /**
   * GET targets proposed (no_limit)
   *
   * @param id
   * @returns list of proposed targets
   */
   public targetProposedNoLimit(id: number | 'me', params?: HttpParams): Observable<any> {
    return this._http.get<any>(`${environment.apiUrl}/users/${id}/targets/proposed?no_limit`, { params });
  }

  /**
   * GET targets in progress (paginate)
   *
   * @param id
   * @returns list of in progress targets
   */
   public targetsProgress(id: number | 'me', params?: HttpParams): Observable<any> {
    return this._http.get<any>(`${environment.apiUrl}/users/${id}/targets/progress`, { params });
  }

  /**
   * GET targets in progress
   *
   * @param id
   * @returns list of in progress targets
   */
  public targetsProgressNoLimit(id: number | 'me' = 'me'): Observable<any> {
    return this._http.get<any>(`${environment.apiUrl}/users/${id}/targets/progress?no_limit`);
  }

  /**
   * GET targets done
   *
   * @param id
   * @param params
   * @returns list of done targets
   */
   public targetsDone(id: number | 'me', params?: HttpParams): Observable<any> {
    return this._http.get<any>(`${environment.apiUrl}/users/${id}/targets/done`, { params });
  }

  public getUserSurvey(id: number | 'me'): Observable<any> {
    return this._http.get<any>(`${environment.apiUrl}/users/${id}/survey`);
  }

  public saveUserSurvey(id: number | 'me', answers: any): Observable<any> {
    return this._http.post<any>(`${environment.apiUrl}/users/${id}/survey/reply/all`, { answers });
  }

  public getUserSKills(id: number): Observable<any> {
    return this._http.get<any>(`${environment.apiUrl}/users/${id}/skills?no_limit`);
  }

  public getSkillsList(): Observable<any> {
    return this._http.get<any>(`${environment.apiUrl}/skills?no_limit`);
  }

  public linkUserSkill(id: number, skills: any): Observable<any> {
    return this._http.request('LINK', `${environment.apiUrl}/users/${id}/skills`, { body: skills });
  }

  public unlinkUserSkill(id: number, skills: any): Observable<any> {
    return this._http.request('UNLINK', `${environment.apiUrl}/users/${id}/skills`, { body: skills });
  }

}
