export const locale = {
  lang: 'pt',
  data: {
    MENU: {
      // DASHBOARD: "",
      // GAINS: "",
      MISSIONS: {
        // COLLAPSIBLE: "",
        // PROPOSED: "",
        // CURRENT: "",
        // CLOSED: ""
      }
    }
  }
}
