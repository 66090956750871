export const locale = {
  lang: 'pt',
  data: {
    LANGUAGE: {
      CHINESE: "Chinês",
      GERMAN: "Alemão",
      ENGLISH: "Inglês",
      SPANISH: "Espanhol",
      FRENCH: "Francês",
      ITALIAN: "Italiano",
      DUTCH: "Holandês",
      POLISH: "Polonês",
      PORTUGUESE: "Português",
      RUSSIAN: "Russo"
    },
    DROPDOWN: {
      // ACCOUNT: "",
      // LOGOUT: ""
    }
  }
}