export const locale = {
  lang: 'cn',
  data: {
    LANGUAGE: {
      CHINESE: "中国人",
      GERMAN: "德语",
      ENGLISH: "英语",
      SPANISH: "西班牙语",
      FRENCH: "法语",
      ITALIAN: "意大利语",
      DUTCH: "荷兰语",
      POLISH: "抛光",
      PORTUGUESE: "葡萄牙语",
      RUSSIAN: "俄语"
    },
    DROPDOWN: {
      // ACCOUNT: "",
      // LOGOUT: ""
    }
  }
}