import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import 'hammerjs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr'; // For auth after login toast

import { CoreModule } from '@core/core.module';
import { CoreCommonModule } from '@core/common.module';
import { CoreSidebarModule, CoreThemeCustomizerModule } from '@core/components';

import { coreConfig } from 'app/app-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { httpInterceptorProviders } from './auth/helpers';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: '/user/dashboard',
    pathMatch: 'full'
  },
  {
    path: 'authentication',
    loadChildren: () => import('./main/authentication/authentication.module').then(m => m.AuthenticationModule)
  },
  {
    path: 'introduction',
    loadChildren: () => import('./main/introduction/introduction.module').then(m => m.IntroductionModule)
  },
  {
    path: 'user',
    loadChildren: () => import('./main/user/user.module').then(m => m.UserModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./main/admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: 'miscellaneous',
    loadChildren: () => import('./main/miscellaneous/miscellaneous.module').then(m => m.MiscellaneousModule)
  },
  {
    path: 'questionnaire',
    loadChildren: () => import('./main/questionnaire/questionnaire.module').then(m => m.QuestionnaireModule)
  },
  {
    path: 'cyberscore',
    loadChildren: () => import('./main/cyberscore/cyberscore.module').then(m => m.CyberscoreModule)
  },
  {
    path: 'asset',
    loadChildren: () => import('./main/asset/asset.module').then(m => m.AssetModule)
  },
  {
    path: 'contributors',
    loadChildren: () => import('./main/contributors/contributors.module').then(m => m.ContributorsModule)
  },
  {
    path: 'guest',
    loadChildren: () => import('./main/guest/guest.module').then(m => m.GuestModule)
  },
  {
    path: '**',
    redirectTo: 'miscellaneous/error' //Error 404 - Page not found
  }
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'enabled', // Add options right here
      relativeLinkResolution: 'legacy'
    }),
    TranslateModule.forRoot(),

    //NgBootstrap
    NgbModule,
    ToastrModule.forRoot(),

    // Core modules
    CoreModule.forRoot(coreConfig),
    CoreCommonModule,
    CoreSidebarModule,
    CoreThemeCustomizerModule,

    // App modules
    LayoutModule,
  ],
  providers: [
    httpInterceptorProviders
  ],

  bootstrap: [AppComponent]
})
export class AppModule {}
