export const locale = {
  lang: 'nl',
  data: {
    LANGUAGE: {
      CHINESE: "Chinese",
      GERMAN: "Duitse",
      ENGLISH: "Engels",
      SPANISH: "Spaans",
      FRENCH: "Frans",
      ITALIAN: "Italiaans",
      DUTCH: "Nederlands",
      POLISH: "Pools",
      PORTUGUESE: "Portugees",
      RUSSIAN: "Russisch"
    },
    DROPDOWN: {
      // ACCOUNT: "",
      // LOGOUT: ""
    }
  }
}