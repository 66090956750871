export const locale = {
  lang: 'es',
  data: {
    LANGUAGE: {
      CHINESE: "Chino",
      GERMAN: "Alemán",
      ENGLISH: "Inglés",
      SPANISH: "Español",
      FRENCH: "Francés",
      ITALIAN: "Italiano",
      DUTCH: "Holandés",
      POLISH: "Polaco",
      PORTUGUESE: "Portugués",
      RUSSIAN: "Ruso"
    },
    DROPDOWN: {
      // ACCOUNT: "",
      // LOGOUT: ""
    }
  }
}