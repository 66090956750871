import { CoreMenu } from "@core/types";

export const menu: CoreMenu[] = [
  {
    id: "dashboard",
    title: "Dashboard",
    translate: "MENU.DASHBOARD.TITLE",
    type: "item",
    icon: "home",
    url: "user/dashboard",
  },
  {
    id: "cyberscore",
    title: "Cyberscore",
    translate: "MENU.CYBERSCORE.TITLE",
    type: "section",
    icon: "layout",
    children: [
      {
        id: "repository",
        title: "My repository",
        translate: "MENU.CYBERSCORE.REPOSITORY",
        type: "item",
        icon: "trello",
        url: "questionnaire/7d1d706d-27da-4c64-a430-d60ead60a04e",
      },
      {
        id: "suggestions",
        title: "Suggestions",
        translate: "MENU.CYBERSCORE.SUGGESTIONS",
        type: "item",
        icon: "layers",
        url: "/user/action-plan",
      },
      {
        id: "actions_tracking",
        title: "Actions tracking",
        translate: "MENU.CYBERSCORE.ACTIONS_TRACKING",
        type: "item",
        icon: "bookmark",
        url: "/cyberscore/tracking",
      },
      // {
      //   id: "tools",
      //   title: "Tools",
      //   translate: "MENU.CYBERSCORE.TOOLS",
      //   type: "item",
      //   icon: "shopping-cart",
      //   url: "user/tracking",
      // },
    ],
  },
  {
    id: "actives",
    title: "Actives",
    translate: "MENU.ACTIVES.TITLE",
    type: "section",
    icon: "hard-drive",
    children: [
      {
        id: "display_actives",
        title: "Display actives",
        translate: "MENU.ACTIVES.DISPLAY_ACTIVES",
        type: "item",
        icon: "server",
        url: "asset/display-asset",
      },
      {
        id: "add_active",
        title: "Add active",
        translate: "MENU.ACTIVES.ADD_ACTIVE",
        type: "item",
        icon: "plus",
        url: "asset/add-asset",
      },
    ],
  },
  {
    id: "contributors",
    title: "Contributors",
    translate: "MENU.CONTRIBUTORS.TITLE",
    type: "section",
    icon: "users",
    children: [
      {
        id: "contributors_list",
        title: "Contributors list",
        translate: "MENU.CONTRIBUTORS.CONTRIBUTORS_LIST",
        type: "item",
        icon: "list",
        url: "contributors/list",
      },
      {
        id: "employee_details",
        title: "Contributor details",
        translate: "MENU.CONTRIBUTORS.CONTRIBUTORS_LIST",
        type: "item",
        icon: "list",
        url: "contributors/details-employee",
        hidden: true
      },
    ],
  },
  {
    id: "onboarding",
    title: "Onboarding",
    translate: "MENU.ONBOARDING.TITLE",
    type: "section",
    icon: "clipboard",
  },
];
