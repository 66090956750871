export const locale = {
  lang: 'ru',
  data: {
    LANGUAGE: {
      CHINESE: "китайский язык",
      GERMAN: "Немецкий",
      ENGLISH: "английский",
      SPANISH: "испанский",
      FRENCH: "французкий язык",
      ITALIAN: "Итальянский",
      DUTCH: "Голландский",
      POLISH: "Польский",
      PORTUGUESE: "португальский",
      RUSSIAN: "русский"
    },
    DROPDOWN: {
      // ACCOUNT: "",
      // LOGOUT: ""
    }
  }
}