export const locale = {
  lang: 'en',
  data: {
    LANGUAGE: {
      CHINESE: "Chinese",
      GERMAN: "German",
      ENGLISH: "English",
      SPANISH: "Spanish",
      FRENCH: "French",
      ITALIAN: "Italian",
      DUTCH: "Dutch",
      POLISH: "Polish",
      PORTUGUESE: "Portuguese",
      RUSSIAN: "Russian"
    },
    DROPDOWN: {
      ACCOUNT: "Account",
      LOGOUT: "Logout"
    }
  }
}